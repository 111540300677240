import { SiteInfoServiceType } from "../../domain/services/site-info-service";
import fetch from "isomorphic-unfetch";
import SiteInfo from "../../domain/models/site-info";
import { parseJsonRes } from "../../utils/parse-res";

export class SiteInfoService implements SiteInfoServiceType {
  async getSiteInfo(): Promise<SiteInfo> {
    const res = await fetch(`${process.env.NEXT_PUBLIC_WEB_API_URL}/site-info`);
    const resData = await parseJsonRes(res);
    const siteInfo = new SiteInfo().fromJson(resData);
    return siteInfo;
  }
}
