export class SiteInfoMenuItem {
  id: number;
  title: string;
  link: string;
  fromJson(data): SiteInfoMenuItem {
    this.id = data.id;
    this.title = data.title;
    this.link = data.link;
    return this;
  }
}

export class SiteInfoContactInfoItem {
  id: number;
  title: string;
  link: string;
  fromJson(data): SiteInfoContactInfoItem {
    this.id = data.id;
    this.title = data.title;
    this.link = data.link;
    return this;
  }
}

export class SiteInfoAddress {
  id: number;
  city: string;
  details: string;
  fromJson(data): SiteInfoAddress {
    this.id = data.id;
    this.city = data.city;
    this.details = data.details;
    return this;
  }
}

export class SiteInfoTelephoneInfoItem {
  id: number;
  title: string;
  telephone: string;
  fromJson(data): SiteInfoTelephoneInfoItem {
    this.id = data.id;
    this.title = data.title;
    this.telephone = data.telephone;
    return this;
  }
}

class SiteInfo {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  menu_items: SiteInfoMenuItem[];
  contact_info_items: SiteInfoContactInfoItem[];
  address: SiteInfoAddress;
  telephone_info_items: SiteInfoTelephoneInfoItem[];

  fromJson(data): SiteInfo {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    if (data.menu_items) {
      this.menu_items = data.menu_items.map((o) =>
        new SiteInfoMenuItem().fromJson(o)
      );
    }
    if (data.contact_info_items) {
      this.contact_info_items = data.contact_info_items.map((o) =>
        new SiteInfoContactInfoItem().fromJson(o)
      );
    }
    if (data.address) {
      this.address = new SiteInfoAddress().fromJson(data.address);
    }
    if (data.telephone_info_items) {
      this.telephone_info_items = data.telephone_info_items.map((o) =>
        new SiteInfoTelephoneInfoItem().fromJson(o)
      );
    }
    return this;
  }
}

export default SiteInfo;
