import { SiteInfoServiceType } from "../services/site-info-service";
import SiteInfo from "../models/site-info";

export class GetPageDefaultsInteractor {
  siteInfoService: SiteInfoServiceType;

  constructor(siteInfoService: SiteInfoServiceType) {
    this.siteInfoService = siteInfoService;
  }

  async getSiteInfo(): Promise<SiteInfo> {
    return this.siteInfoService.getSiteInfo();
  }
}
