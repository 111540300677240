import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import { Link, scroller, Element } from 'react-scroll';
import SiteInfo from '../../domain/models/site-info';
import { Hidden } from '@material-ui/core';

type PropsType = {
  children: React.ReactNode;
  siteInfo?: SiteInfo;
};

const Main = styled.main`
  padding-bottom: 100px;
`;

function DefaultLayout(props: PropsType) {
  const { children, siteInfo } = props;
  const [menuItemsSorted, setMenuItemsSorted] = useState([]);
  const [navExpanded, setNavExpanded] = useState(false);

  useEffect(() => {
    if (siteInfo?.menu_items) {
      setMenuItemsSorted(siteInfo.menu_items);
    } else {
      setMenuItemsSorted([]);
    }
  }, [siteInfo]);

  const renderMenuItem = (menuItem) => {
    if (menuItem.link && menuItem.link.startsWith('#')) {
      if (window?.location?.pathname == '/') {
        return (
          <li key={menuItem.id}>
            <Link
              to={menuItem.link.replace('#', '')}
              spy={true}
              hashSpy={true}
              smooth={true}
              duration={500}
              offset={-100}
              href={menuItem.link}>
              {menuItem.title}
            </Link>
          </li>
        );
      }
      return (
        <li key={menuItem.id}>
          <a href={`/${menuItem.link}`}>{menuItem.title}</a>
        </li>
      );
    }
    return (
      <li key={menuItem.id}>
        <a href={menuItem.link}>{menuItem.title}</a>
      </li>
    );
  };

  const renderContact = () => {
    return (
      <section id="contact" className="contact">
        <div className="container pad-top-20">
          <h3 className="header white">
            <span className="orange">+</span>Contact Us
          </h3>
          <div className="row pad-top-60">
            <div className="col-md-6">
              <h1 className="orange">Contact Us</h1>
              {siteInfo.contact_info_items.map((i) => (
                <h2 key={i.id}>
                  <a href={i.link}>{i.title}</a>
                </h2>
              ))}
            </div>
            <div className="right col-md-3 col-md-offset-3">
              <h2 className="orange">{siteInfo.address?.city}</h2>
              <h3 className="white">
                {siteInfo.address?.details.indexOf('\n') >= 0 ? (
                  siteInfo.address.details.split('\n').map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  ))
                ) : (
                  <span>{siteInfo.address?.details}</span>
                )}
              </h3>
              <div className="row pad-top-20">
                <div className="col-md-3 col-xs-3">
                  <img src="/images/phone.png" />
                </div>
                <div className="col-md-9 col-xs-9">
                  {siteInfo.telephone_info_items.map((t) => (
                    <span key={t.id}>
                      {t.title}: {t.telephone}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderFooter = () => {
    return (
      <footer>
        <div className="container">
          <img src="/images/nav-logo.jpg" />
          <p>Copyright © 2021</p>
          <div className="clearfix"></div>
        </div>
      </footer>
    );
  };

  const renderNav = () => {
    return (
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              onClick={(e) => {
                setNavExpanded(!navExpanded);
              }}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img src="/images/nav-logo.jpg" />
            </a>
          </div>
          <div id="navbar" className={navExpanded ? 'navbar-collapse' : 'navbar-collapse collapse'}>
            <ul className="nav navbar-nav">
              {menuItemsSorted.map((m) => renderMenuItem(m))}
              <Hidden mdDown>
                <li>
                  <a className="sm-img" href="https://twitter.com/voxusa" target="_blank">
                    <img src="/images/twitter.jpg" />
                  </a>
                </li>
                <li>
                  <a
                    className="sm-img"
                    href="https://www.facebook.com/pages/VOX-Inc/440730785695\"
                    target="_blank">
                    <img src="/images/facebook.jpg" />
                  </a>
                </li>
                <li>
                  <a
                    className="sm-img"
                    href="https://www.instagram.com/vox_inc_usa"
                    target="_blank">
                    <img src="/images/instagram.png" />
                  </a>
                </li>
              </Hidden>
            </ul>
          </div>
        </div>
      </nav>
    );
  };

  return (
    <>
      <Head>
        <title>VOX Inc.</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <Main>
        {siteInfo?.menu_items && renderNav()}
        {children}
        {siteInfo?.contact_info_items && renderContact()}
        {siteInfo && renderFooter()}
      </Main>
    </>
  );
}

export default DefaultLayout;
